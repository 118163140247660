import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo"
import { Row, Col, Card, Modal } from "react-bootstrap";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import { getSessionData } from "../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import Tabela from "../../../components/Formularios/Table";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { mdiEmailFastOutline } from '@mdi/js';
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";
import { mascaraCPF } from "../../../hooks/mascaras";
import { mascaraTelefoneCelular } from "../../../hooks/mascaras";
import { mascaraTelefoneFixo } from "../../../hooks/mascaras";
import { Button as ButtonCustom } from "../../../components/Formularios/Buttons/Button";
import {
    mdiCheckCircle,
    mdiKeyboardReturn,
    mdiLockCheckOutline,
} from "@mdi/js";
function PreCadastro() {

    const navigate = useNavigate()

    interface Action {
        icon: JSX.Element;
        name: string;
    }
    const actions: Action[] = [
        {
            icon: (
                <AddIcon
                    onClick={() => navigate("/precadastro/clientes/formulario/novo/criar")}
                />
            ),
            name: "Incluir",
        },
        // { icon: <FileCopyIcon />, name: "Duplicar" },
        // { icon: <SaveIcon />, name: "Salvar" },
        // { icon: <PrintIcon />, name: "Imprimir" },
    ]

    // Função para realizar a busca
    const [busca, set_busca] = useState("")
    function Search(string: any) {
        // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
        let termoBusca = string.toLowerCase()

        if (termoBusca == "") {
            carregarClientes(getSessionData("DadosIdfilial"))
        }
        else {
            // Use a função filter para encontrar as pessoas que correspondem à busca
            const resultados = clientes.filter(function (info: any) {

                // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
                let buscando = info.nome.toLowerCase()

                // Verifique se contém o termo de busca
                return buscando.includes(termoBusca)

            })


            set_clientes(resultados)
        }
    }

    useEffect(function () {
        Search(busca)
    }, [busca])


    const [clientes, set_clientes] = useState([])
    function carregarClientes(id_filial: any) {

        set_carregando(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/clientes/${id_filial}`, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        })
            .then(function (resposta) {

                set_carregando(false)
                if (resposta.data.codigo != 200) {

                    toast.error(resposta.data.message)
                }
                else {

                    set_clientes(resposta.data.clientes)
                }
            }).catch(function (erro) {

                set_carregando(false)
                toast.error(erro)
            })
    }


    const colunas: ITabela[] = [
        { titulo: "ID", acesso: "id_cliente" },
        { titulo: "CPF", acesso: "cpf" },
        { titulo: "Nome", acesso: "nome" },
        { titulo: "Email", acesso: "email" },
        { titulo: "Celular", acesso: "telcelular" },
        { titulo: "Telefone", acesso: "telfixo" },
        { titulo: "Status", acesso: "status" },
        { titulo: "Situação envio de E-mail", acesso: "status_email" },
        { titulo: "Status Pré Cadastro", acesso: "status_pre_cad" }
    ]

    //modal loading
    const [carregando, set_carregando] = useState(false)



    async function enviarEmail(dado: any) {

        await showConfirmationDialog("Enviar e-mail ?",
            "Continuar envio de e-mail ?", "question", 1).then(function (resposta: any) {

                if (resposta.confirmed) {
                    set_carregando(true)

                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/enviar/email/pre/cad/${getSessionData("DadosIdfilial")}/${dado.email}/${dado.id_cliente}`, {
                        headers: {
                            Authorization: getSessionData("MultfilialWebToken")
                        }
                    })
                        .then(function (resposta) {

                            set_carregando(false)
                            if (resposta.data.codigo != 200) {

                                toast.error(resposta.data.message)
                            }
                            else {

                                toast.success(resposta.data.message)
                                carregarClientes(getSessionData("DadosIdfilial"))
                            }
                        }).catch(function (erro) {

                            set_carregando(false)
                            toast.error(erro)
                        })
                }
            })
    }


    //verifica permissão --- inicio
    const [permissao_incluir, set_permissao_incluir] = useState(true)
    const [permissao_enviar, set_permissao_enviar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_editar, set_permissao_editar] = useState(true)

    async function CarregarPermissões() {
        set_carregando(true);
        const headers = {
            Authorization: getSessionData("MultfilialWebToken") || "",
        };
        const dados = {
            url: "/perfil/carregar/:idperfil",
            parametros: {
                idperfil: getSessionData("id_perfil") || "",
            },
            headers: headers,
        };
        ApiGet(dados).then(function (response) {
            if (response[0].EnviarPreCad === 1) {
                set_permissao_enviar(false)
            }
            if (response[0].incluirPreCad === 1) {
                set_permissao_incluir(false)
            }
            if (response[0].ExcluirPreCad === 1) {
                set_permissao_excluir(false)
            }
            if (response[0].editar_usuario === 1) {
                set_permissao_editar(false)
            }
        })
    }


    async function excluir(dado: any) {

        await showConfirmationDialog("Excluir Cadastro ?",
            "Deseja continuar ?", "question").then(function (resposta) {

                if (resposta.confirmed) {

                    set_carregando(true)

                    axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/cliente/${getSessionData("DadosIdfilial")}/${dado.id_cliente}`, {
                        headers: {
                            Authorization: getSessionData("MultfilialWebToken")
                        }
                    })
                        .then(function (resposta) {

                            set_carregando(false)
                            if (resposta.data.codigo == 200) {

                                toast.success(resposta.data.message)
                                carregarClientes(getSessionData("DadosIdfilial"))
                            }
                            else {

                                toast.error(resposta.data.message)
                            }
                        }).catch(function (erro) {

                            set_carregando(false)
                            toast.error(erro)
                        })
                }
            })
    }


    useEffect(function () {

        carregarClientes(getSessionData("DadosIdfilial"))
        CarregarPermissões()
    }, [])

    //ataulizar pré cadastro
    const [cpf, set_cpf] = useState("")
    const [nome, set_nome] = useState("")
    const [email, set_email] = useState("")
    const [celular, set_celular] = useState("")
    const [telefone, set_telefone] = useState("")
    const [id_cliente, set_id_cliente] = useState("")
    const [showModalAtualizarPrecad, setShowModalAtualizarPrecad] = useState(false)
    function abrirModalAtualizarPrecad(dados?: any) {
        setShowModalAtualizarPrecad(!showModalAtualizarPrecad)

        //seta as infos do pre-cad
        if (showModalAtualizarPrecad == false) {
            set_id_cliente(dados.id_cliente)
            set_cpf(mascaraCPF(dados.cpf))
            set_nome(dados.nome)
            set_email(dados.email)
            set_celular(mascaraTelefoneCelular(dados.telcelular))
            set_telefone(mascaraTelefoneFixo(dados.telfixo))
        }
    }
    function EditarPrecadastro(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        set_carregando(true)
        const dados = {

            id_filial: getSessionData("DadosIdfilial"),
            usuario_cadastro: getSessionData("NomeUsuario"),
            cpf: cpf,
            nome: nome,
            email: email,
            celular: celular,
            telefone: telefone
        }
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/atualizar/pre/cad/cliente/${id_cliente}`, dados, {
            headers: {
                Authorization: getSessionData("MultfilialWebToken")
            }
        }).then(function (resposta) {
            abrirModalAtualizarPrecad()
            carregarClientes(getSessionData("DadosIdfilial"))
            toast.success(resposta.data.message)
            set_carregando(false)
        }).catch(function (erro) {
            toast.error(erro.response.data.message || erro.message)
            set_carregando(false)
        })
    }
    return (

        <>
            <div className="col">
                <ContainerTitulo titulo="Listagem de Pré cadastro - Clientes" />

                <Row>
                    <Col sm={12}>

                        <Card>
                            <Card.Body>
                                <Row>
                                    <SpeedDial
                                        ariaLabel="SpeedDial basic example"
                                        direction="left"
                                        sx={{
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        icon={<SpeedDialIcon />}
                                    >  {actions.map((action) => (




                                        <SpeedDialAction
                                            hidden={action.name == "Incluir" ? permissao_incluir : false}
                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                        />
                                    ))}
                                    </SpeedDial>
                                </Row>
                                <Row>
                                    <div className="d-flex my-2 gap-1 ">
                                        <Col sm={11}>
                                            <InputSemBorda
                                                name="fantasia"
                                                type="text"
                                                value={busca}
                                                onChange={set_busca}
                                                classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                                placeholder=" "
                                                label="Procurar por cliente"
                                            />
                                        </Col>
                                    </div>
                                </Row>

                                <Row>
                                    <Tabela
                                        coluna={colunas}
                                        dados={clientes}
                                        itemsPerPage={10}
                                        onVisuClick={enviarEmail}
                                        onEditClick={abrirModalAtualizarPrecad}
                                        onDeleteClick={excluir}
                                        iconeVisualizar={mdiEmailFastOutline}
                                        id="codigo"
                                        descVisualizar="Enviar e-mail"
                                        permissao_editar={permissao_editar}
                                        permissao_visualizar={permissao_enviar}
                                        permissao_excluir={permissao_excluir}
                                    />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <ModalLoading show={carregando} />
            <Modal show={showModalAtualizarPrecad} size="xl" centered onHide={abrirModalAtualizarPrecad}>
                <Modal.Header className="bg-white" closeButton>
                    <h4>Atualizar pré-cadastro</h4>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <form onSubmit={EditarPrecadastro}>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <InputSemBorda
                                        name="fantasia"
                                        type="text"
                                        value={id_cliente}
                                        onChange={function () {

                                        }}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="ID Cliente"
                                    />
                                </div>
                                <div className="col-3">
                                    <InputSemBorda
                                        name="text"
                                        type="text"
                                        required
                                        value={mascaraCPF(cpf)}
                                        onChange={set_cpf}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="CPF"
                                    />
                                </div>
                                <div className="col">
                                    <InputSemBorda
                                        name="nome"
                                        type="text"
                                        value={nome}
                                        required
                                        onChange={set_nome}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Nome"
                                    />
                                </div>
                                <div className="w-100"></div>
                                <div className="col-5">
                                    <InputSemBorda
                                        name="e-mail"
                                        type="text"
                                        value={email}
                                        required
                                        onChange={set_email}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="E-mail"
                                    />
                                </div>
                                <div className="col-3">
                                    <InputSemBorda
                                        name="celular"
                                        type="text"
                                        required
                                        value={mascaraTelefoneCelular(celular)}
                                        onChange={set_celular}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Celular"
                                    />
                                </div>
                                <div className="col">
                                    <InputSemBorda
                                        name="telefone"
                                        type="text"
                                        value={mascaraTelefoneFixo(telefone)}
                                        onChange={set_telefone}
                                        classNameInputsSemBorda="form-control bordasInferiorInput text-dark"
                                        placeholder=" "
                                        label="Telefone"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm col-md-12 col-lg-12 text-end">
                                <ButtonCustom
                                    invisivel={false}
                                    type="submit"
                                    className="btn btn-light text-info-emphasis bg-white border-0"
                                    descricaoBotao="Salvar"
                                    icone={mdiCheckCircle}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default PreCadastro